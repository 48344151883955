import { useCallback, useEffect, useMemo, useState } from 'react';
import { Translation } from 'shared/components/translation/translation.component';
import { NotificationRow } from './notification-row.component';
import { PreferencesCallToAction } from './preferences-call-to-actions.component';
import { CommunicationChannelType } from './preferences-communication-types';
import { PreferencesFeedback } from './preferences-feedback.component';
import { useGetCommunicationPreferences, useUpdateCommunicationPreferences } from './use-notifications-preferences.hook';
import { useToastManager } from '@shared/components/toast/use-toasts.hook';
import { Observable, of } from 'rxjs';
import { useToggleNotificationEvent } from './use-toggle-notifications-event.hook';
import { PreferencesPrompt } from './preferences-prompt.component';

import './notifications-preferences.component.scss';

export const PREFERENCES_SETTING_SECTION = 'preferences-setting-section';

export const NotificationsPreferencesComponent = ({ contactId }: { contactId: string }) => {
	const toast = useToastManager();
	const toggleNotificationEvent = useToggleNotificationEvent();

	const hash = window?.location?.hash;
	const [communicationPreferences, error, refresh] = useGetCommunicationPreferences(contactId);
	const [normalizedPreferences, setNormalizedPreferences] = useState({});
	const [initialPreferences, setInitialPreferences] = useState({});

	const unsavedChanges = useMemo(() => {
		let count = 0;
		Object.entries(initialPreferences).forEach(([key, value]) => {
			count = normalizedPreferences[key] !== value ? count + 1 : count;
		});
		return count;
	}, [initialPreferences, normalizedPreferences]);

	const initialization = useCallback(
		preferences => {
			const keyValue = preferences.reduce((acc, preference) => {
				acc[preference.key] = preference.communications.some(communication => communication.isEnabled) ? true : false;
				return acc;
			}, {});
			setNormalizedPreferences(keyValue);
			setInitialPreferences(keyValue);
		},
		[setNormalizedPreferences, setInitialPreferences]
	);

	const restartInitialValue = useCallback(() => {
		setNormalizedPreferences(normalizedPreferences);
		setInitialPreferences(normalizedPreferences);
	}, [setNormalizedPreferences, setInitialPreferences, normalizedPreferences]);

	useEffect(() => {
		if (communicationPreferences) {
			initialization(communicationPreferences.communicationPreferences);
		}
	}, [communicationPreferences, initialization]);

	const onSuccess = useCallback(() => {
		restartInitialValue();
		toggleNotificationEvent(normalizedPreferences);
		toast.success([<Translation resource="YOUR_CHANGES_HAVE_BEEN_SAVED" />]);
		return Observable.empty();
	}, [normalizedPreferences, initialization]);

	const onErrror = useCallback(err => {
		toast.error([<Translation resource="SOMETHING_WENT_WRONG" />]);
		return Observable.empty();
	}, []);

	const updateCommunicationPreferences = useUpdateCommunicationPreferences(onSuccess, onErrror);

	useEffect(() => {
		// When a user naviatages to the setting page from a hash url, scroll the finance section in view.
		if (hash && hash.includes(PREFERENCES_SETTING_SECTION)) {
			const section = document.getElementById(PREFERENCES_SETTING_SECTION);
			if (section) {
				setTimeout(() => {
					window.scrollTo({
						top: section.offsetTop,
						behavior: 'smooth',
					});
				}, 200);
			}
		}
	}, []);

	const handleCheck = key => {
		setNormalizedPreferences(prev => ({ ...prev, [key]: !prev[key] }));
	};

	async function onSave() {
		const newPreferences = communicationPreferences.communicationPreferences.map(preference => {
			return {
				communicationPreferencesId: preference.communicationPreferenceId,
				userCommunications: preference.communications.map(communication => {
					return {
						communicationId: communication.communicationId,
						communicationChannelPreferences: preference.communications.map(channel => {
							return {
								communicationChannelId: CommunicationChannelType.InApp,
								isEnabled: normalizedPreferences[preference.key],
							};
						}),
					};
				}),
			};
		});

		updateCommunicationPreferences({ contactId: contactId, communicationGroupPreferences: newPreferences });
		return of(true);
	}

	return (
		<div className="ns-card content-card space-outer-top-md shadow notifications-preferences-setting" id={PREFERENCES_SETTING_SECTION}>
			<h2 className="finance-setting-section-heading">
				<Translation resource="NOTIFICATIONS_PREFERENCES" />
			</h2>
			{communicationPreferences &&
				communicationPreferences.communicationPreferences.map((notification, index) => {
					return (
						<NotificationRow
							key={index}
							title={notification.key}
							subtitle={notification.key}
							list={notification.communications}
							isEnabled={!!normalizedPreferences[notification.key]}
							handleCheck={handleCheck}
						/>
					);
				})}
			<PreferencesFeedback />
			<PreferencesPrompt when={unsavedChanges > 0} onOK={() => true} onCancel={onSave} />
			<PreferencesCallToAction unsavedChanges={unsavedChanges} onCancel={refresh} onSave={onSave} />
		</div>
	);
};
