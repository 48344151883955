import { Box } from '@mui/material';
import { Button } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';

import './preferences-feedback.component.scss';

export const PreferencesFeedback = () => {
  return (
    <Box className="preferences-feedback">
      <div className="feedback-container">
        <Box sx={{ marginBottom: 3 }} className="font-bold font-size-md">
          <Translation resource="NOTIFICAITON_FEEDBACK_QUESTION" />
        </Box>
        <Button
          id="feedback-preferences-notification-button"
          btnDefault
          resource="FEEDBACK"
          mobileResponsive
        />
      </div>
    </Box>
  );
};
